/* Data that will be obtain by call to backend
* */

const checklist_arrays = [
  [
    [
      '"id"',
      '"name"',
      '"parent_id"',
      '"position"',
      '"comment"',
      '"section_title"',
      '"cond"',
      '"check"',
      '"color"',
      '"pre_check"',
      '"importance'
    ],
    [
      1,
      '"Dossier infirmier présent"',
      -1,
      0,
      null,
      '"Infirmière de l\'unité de soin qui prépare le patient"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      2,
      '"Patient"',
      -1,
      1,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["list_patient-states"]',
      '[0]',
      null,
      0
    ],
    [
      3,
      '"Le patient décline son nom"',
      -1,
      2,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      4,
      '"Le patient décline son prénom"',
      -1,
      3,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      5,
      '"Le patient décline sa date de naissance"',
      -1,
      4,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      6,
      '"Vérifier la concordance entre le dossier et le bracelet d\'identité du patient"',
      -1,
      5,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["ok","not_ok"]',
      '[0,1]',
      null,
      0
    ],
    [
      7,
      '"Consentement du patient"',
      -1,
      6,
      '"Pdf du Consentement"',
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":17}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      8,
      '"Consentement des deux parents ( <18 ans )"',
      -1,
      7,
      '"Pdf du Consentement"',
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":"<","val":18}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      9,
      '"Le patient décline le côté à opérer"',
      -1,
      8,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["left","right","bilateral","idk","na"]',
      '[2,2,2,1,2]',
      null,
      0
    ],
    [
      10,
      '"Marquage réalisé ?"',
      -1,
      9,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      11,
      '"Préparation du site opératoire"',
      -1,
      10,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["ok","not_ok"]',
      '[0,1]',
      null,
      0
    ],
    [
      12,
      '"L\'infirmière vérifie dans le dossier le type d\'intervention"',
      -1,
      11,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      13,
      '"Maladies transmissibles connues"',
      -1,
      12,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      14,
      '"Lesquelles ?_Maladies"',
      13,
      0,
      null,
      null,
      '{"yes":[13],"no":[],"num":[]}',
      '["list_diseases"]',
      '[1]',
      null,
      0
    ],
    [
      15,
      '"Patient(e) sous Xarelto. Arrêté depuis 48h ?"',
      -1,
      13,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"xarelto","op":"=","val":"vrai"}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      1
    ],
    [
      16,
      '"Patient(e) sous Insuline. Pas d’injection personnelle ce matin ?"',
      -1,
      14,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"insulin","op":"=","val":"vrai"}]}',
      '["yes","no"]',
      '[1,0]',
      null,
      1
    ],
    [
      17,
      '"Patient(e) à jeun"',
      -1,
      15,
      null,
      '"À vérifier"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      18,
      '"Vernis à ongles ôté"',
      -1,
      16,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      19,
      '"A uriné"',
      -1,
      17,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      20,
      '"Prothèses retirées (dentier, appareil auditif, lentilles)"',
      -1,
      18,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      21,
      '"Bijoux/piercings ôtés"',
      -1,
      19,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],

    [
      22,
      '"Médicamenteuses déclarées"',
      -1,
      20,
      null,
      '"Le patient déclare ses allergies"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      23,
      '"Lesquelles ?_Allergies_Meds"',
      22,
      0,
      null,
      null,
      '{"yes":[22],"no":[],"num":[]}',
      '["list_alergy-meds"]',
      '[1]',
      null,
      0
    ],
    [
      24,
      '"Alimentaires déclarées"',
      -1,
      21,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      25,
      '"Lesquelles ?_Allergies_Alim"',
      24,
      0,
      null,
      null,
      '{"yes":[24],"no":[],"num":[]}',
      '["list_alergy-aliments"]',
      '[1]',
      null,
      0
    ],
    [
      26,
      '"Hygiène du patient correcte"',
      -1,
      22,
      null,
      '"Hygiène"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      27,
      '"Propreté et intégrité du site opératoire"',
      -1,
      23,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      28,
      '"Présence de bas de contention"',
      -1,
      24,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      29,
      '"Scan :"',
      -1,
      25,
      null,
      '"Infirmièr(e) de l\'US qui a préparé le patient pour le BO "',
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0]',
      null,
      0
    ]
  ],
  [
    [
      '"id"',
      '"name"',
      '"parent_id"',
      '"position"',
      '"comment"',
      '"section_title"',
      '"cond"',
      '"check"',
      '"color"',
      '"pre_check"',
      '"importance'
    ],
    [
      1,
      '"Dossier infirmier présent"',
      -1,
      0,
      null,
      '"Infirmière BO qui accueille le patient"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      2,
      '"Patient"',
      -1,
      1,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["list_patient-states"]',
      '[0]',
      null,
      0
    ],
    [
      3,
      '"Le patient décline son nom"',
      -1,
      2,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      4,
      '"Le patient décline son prénom"',
      -1,
      3,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      5,
      '"Le patient décline sa date de naissance"',
      -1,
      4,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      6,
      '"Vérifier la concordance entre le dossier et le bracelet d\'identité du patient"',
      -1,
      5,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["ok","not_ok"]',
      '[0,1]',
      null,
      0
    ],
    [
      7,
      '"Consentement du patient"',
      -1,
      6,
      '"Pdf du Consentement"',
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":17}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      8,
      '"Consentement des parents ( <18 ans )"',
      -1,
      7,
      '"Pdf du Consentement"',
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":"<","val":18}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      9,
      '"Le patient décline le côté à opérer"',
      -1,
      8,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["left","right","bilateral","idk","na"]',
      '[2,2,2,1,2]',
      null,
      0
    ],
    [
      10,
      '"Marquage réalisé ?"',
      -1,
      9,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      11,
      '"Préparation du site opératoire"',
      -1,
      10,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["ok","not_ok"]',
      '[0,1]',
      null,
      0
    ],
    [
      12,
      '"L\'infirmière vérifie dans le dossier le type d\'intervention"',
      -1,
      11,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      13,
      '"Maladies transmissibles connues"',
      -1,
      12,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      14,
      '"Lesquelles ?_Maladies"',
      13,
      0,
      null,
      null,
      '{"yes":[13],"no":[],"num":[]}',
      '["list_diseases"]',
      '[1]',
      null,
      0
    ],
    [
      15,
      '"Arrêt coagulant ?"',
      -1,
      13,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      16,
      '"Patient(e) à jeun"',
      -1,
      14,
      null,
      '"À vérifier"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      17,
      '"Vernis à ongles ôté"',
      -1,
      15,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      18,
      '"A uriné"',
      -1,
      16,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      19,
      '"Proth. dentaire/auditives/lentilles ôtées"',
      -1,
      17,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      20,
      '"Bijoux/piercings ôtés"',
      -1,
      18,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      21,
      '"Patient(e) sous Xarelto. Arrêté depuis 48h ?"',
      -1,
      19,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"xarelto","op":"=","val":"vrai"}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      22,
      '"Patient(e) sous Insuline. Pas d’injection personnelle ce matin ?"',
      -1,
      20,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"insulin","op":"=","val":"vrai"}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      23,
      '"Médicamenteuses déclarées"',
      -1,
      21,
      null,
      '"Le patient déclare ses allergies"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      24,
      '"Lesquelles ?_Allergies_Meds"',
      23,
      0,
      null,
      null,
      '{"yes":[23],"no":[],"num":[]}',
      '["list_alergy-meds"]',
      '[1]',
      null,
      0
    ],
    [
      25,
      '"Alimentaires déclarées"',
      -1,
      22,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      26,
      '"Lesquelles ?_Allergies_Alim"',
      25,
      0,
      null,
      null,
      '{"yes":[25],"no":[],"num":[]}',
      '["list_alergy-aliments"]',
      '[1]',
      null,
      0
    ],
    [
      27,
      '"Hygiène du patient correcte"',
      -1,
      23,
      null,
      '"Hygiène"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      28,
      '"Propreté et intégrité du site opératoire"',
      -1,
      24,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      29,
      '"Présence de bas de contention"',
      -1,
      25,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"age","op":">","val":11}]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      30,
      '"Scan :"',
      -1,
      26,
      null,
      '"Infirmière qui accueille le patient au BO :"',
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0]',
      null,
      0
    ]
  ],
  [
    [
      '"id"',
      '"name"',
      '"parent_id"',
      '"position"',
      '"comment"',
      '"section_title"',
      '"cond"',
      '"check"',
      '"color"',
      '"pre_check"',
      '"importance'
    ],
    [
      1,
      '"Date de l\'intervention"',
      -1,
      0,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["date"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      2,
      '"Heure"',
      -1,
      1,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["hour"]',
      '[0]',
      null,
      0
    ],
    [
      3,
      '"Salle "',
      -1,
      2,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["list_room"]',
      '[0]',
      null,
      0
    ],
    [
      4,
      '"Présentation des personnes inhabituelles"',
      -1,
      3,
      null,
      '"Chirurgien / Inf. circul. / Instrumentaliste / Anesthésiste"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      5,
      '"Vérification identité du patient"',
      -1,
      4,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      6,
      '"Rappel de l\'intitulé de l\'intervention"',
      -1,
      5,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      7,
      '"Site opératoire"',
      -1,
      6,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["left","right","bilateral","idk","na"]',
      '[2,2,2,1,2]',
      null,
      0
    ],
    [
      8,
      '"Maladies transmissibles"',
      -1,
      7,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[1,0]',
      null,
      0
    ],
    [
      9,
      '"Lesquelles ?_Maladies"',
      8,
      0,
      null,
      null,
      '{"yes":[8],"no":[],"num":[]}',
      '["list_diseases"]',
      '[1]',
      null,
      0
    ],
    [
      10,
      '"Poches de sang disponibles ?"',
      -1,
      8,
      '"Poches de sang commandées : _bloodbags"',
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["number"]',
      '[1]',
      null,
      0
    ],
    [
      11,
      '"Cell saver disponible"',
      -1,
      9,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"type","op":"=","val":"major"}]}',
      '["yes","no"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      12,
      '"Cell saver en ordre de marche"',
      -1,
      10,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"type","op":"=","val":"major"}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      13,
      '"AB Prophyl."',
      -1,
      11,
      null,
      '"Vérifications administration"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[2,2]',
      null,
      0
    ],
    [
      14,
      '"Lesquels ?_Antibotiques"',
      13,
      0,
      null,
      null,
      '{"yes":[13],"no":[],"num":[]}',
      '["list_antibiotics"]',
      '[0]',
      null,
      0
    ],
    [
      15,
      '"Type d\'anésthésie "',
      -1,
      12,
      null,
      '"Anésthesiste"',
      '{"yes":[],"no":[],"num":[]}',
      '["list_anesthesia"]',
      '[0]',
      null,
      0
    ],
    [
      16,
      '"Matériel d\'anesthésie disponible / ordre de marche"',
      -1,
      13,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      17,
      '"Respirateur"',
      -1,
      14,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      18,
      '"Oxym. de pouls"',
      -1,
      15,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      19,
      '"Monitoring"',
      -1,
      16,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      20,
      '"Matériel de monitorage invasif disponible (cathé artériel, VVC,…) "',
      -1,
      17,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"type","op":"=","val":"major"}]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      21,
      '"Possibilité de difficultés d’intubation signalée"',
      -1,
      18,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      1
    ],
    [
      22,
      '"Matériel d’intubation difficile disponible ?"',
      21,
      0,
      null,
      null,
      '{"yes":[21],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      23,
      '"Fibroscope en ordre de marche"',
      21,
      1,
      null,
      null,
      '{"yes":[21],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      24,
      '"2e anesthésiste disponible ?"',
      21,
      2,
      null,
      null,
      '{"yes":[21],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      25,
      '"Matériel pour l\'infirmière circulante disponible et en ordre de marche "',
      -1,
      19,
      null,
      '"Infirmière circulante"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      26,
      '"Imagerie disponible "',
      -1,
      20,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      27,
      '"Implants stériles et adaptés "',
      -1,
      21,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      28,
      '"La stérilité du matériel disposable et non disposable a été vérifiée (résultats des indicateurs + vérification date péremption)"',
      -1,
      22,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
  ],
  [
    [
      '"id"',
      '"name"',
      '"parent_id"',
      '"position"',
      '"comment"',
      '"section_title"',
      '"cond"',
      '"check"',
      '"color"',
      '"pre_check"',
      '"importance'
    ],
    [
      1,
      '"Date de l\'intervention"',
      -1,
      0,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["date"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      2,
      '"Heure"',
      -1,
      1,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["hour"]',
      '[0]',
      null,
      0
    ],
    [
      3,
      '"Salle "',
      -1,
      2,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["list_room"]',
      '[0,1]',
      null,
      0
    ],
    [
      4,
      '"Présentation des personnes inhabituelles"',
      -1,
      3,
      null,
      '"Chirurgien / Inf. circul. / Instrumentaliste / Anesthésiste"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      5,
      '"Vérification identité du patient"',
      -1,
      4,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      6,
      '"Rappel de l\'intitulé de l\'intervention"',
      -1,
      5,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      7,
      '"Site opératoire"',
      -1,
      6,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["left","right","bilateral","na"]',
      '[2,2,2,2,2]',
      null,
      0
    ],
    [
      8,
      '"Maladies transmissibles"',
      -1,
      7,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[1,0]',
      null,
      0
    ],
    [
      9,
      '"Lesquelles ?_Maladies"',
      8,
      0,
      null,
      null,
      '{"yes":[8],"no":[],"num":[]}',
      '["list_diseases"]',
      '[1]',
      null,
      0
    ],
    [
      10,
      '"Risque de perte sanguine ?"',
      -1,
      8,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"type","op":"=","val":"major"}]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      11,
      '"Poches de sang disponibles ?"',
      10,
      0,
      null,
      null,
      '{"yes":[10],"no":[],"num":[]}',
      '["number"]',
      '[1]',
      null,
      0
    ],
    [
      12,
      '"Vérification installation patient correcte"',
      -1,
      9,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      13,
      '"Vérification plaque bistouri"',
      -1,
      10,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"type","op":"=","val":"major"}]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      14,
      '"Vérification position patient"',
      -1,
      11,
      null,
      null,
      '{"yes":[],"no":[],"num":[{"var":"type","op":"=","val":"major"}]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      15,
      '"Documents nécessaires disponibles (RX, Imagerie, CD, ..)"',
      -1,
      12,
      null,
      '"Chirurgien"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      16,
      '"Particularité de l\'intervention liée à la chirurgie énoncée"',
      -1,
      13,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      17,
      '"Matériel pour le chirurgien et/ou l\'instrumentiste disponible"',
      -1,
      14,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      18,
      '"Vérif. compte initial des compresses"',
      -1,
      15,
      null,
      '"Anésthesiste"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
  ],
  [], // Compte des compresses
  [
    [
      '"id"',
      '"name"',
      '"parent_id"',
      '"position"',
      '"comment"',
      '"section_title"',
      '"cond"',
      '"check"',
      '"color"',
      '"pre_check"',
      '"importance'
    ],
    [
      1,
      '"Prélévements"',
      -1,
      0,
      null,
      '"Confirmation orale par le personnel auprès de l\'équipe"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      2,
      '"Etiquetés correctement"',
      1,
      0,
      null,
      null,
      '{"yes":[1],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      3,
      '"Acheminés correctement"',
      1,
      1,
      null,
      null,
      '{"yes":[1],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      4,
      '"Signature"',
      -1,
      1,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["signature"]',
      '[0]',
      null,
      0
    ],
    [
      5,
      '"Signalement/notification écrite des dysfonctionnements matériels majeurs au responsable BO présent"',
      -1,
      2,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      6,
      '"Dysfonctionnement/ Disponibilité du matériel implantable (Vis, prothèse, greffe, ...)"',
      -1,
      3,
      null,
      '"Chirurgien / Inf. circul. / Instrumentaliste / Anesthésiste"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no","na"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      7,
      '"Drains, sonde urinaire, tamponnement, méchage, traitements, ..."',
      -1,
      4,
      null,
      '"Les prescriptions pour les suites opératoires sont vérfiées de manière conjointe (post op)"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no", "na"]',
      '[0,1,2]',
      null,
      0
    ],
    [
      8,
      '"Tout OK ?"',
      -1,
      5,
      null,
      '"Débriefing"',
      '{"yes":[],"no":[],"num":[]}',
      '["yes","no"]',
      '[0,1]',
      null,
      0
    ],
    [
      9,
      '"Quels problèmes rencontrés ? "',
      8,
      0,
      null,
      null,
      '{"yes":[],"no":[8],"num":[]}',
      '["list_problems"]',
      '[0]',
      null,
      0
    ],
  ],
  [
    [
      '"id"',
      '"name"',
      '"parent_id"',
      '"position"',
      '"comment"',
      '"section_title"',
      '"cond"',
      '"check"',
      '"color"',
      '"pre_check"',
      '"importance'
    ],
    [
      1,
      '"Anesthésiste : Scan"',
      -1,
      0,
      null,
      '"Anesthésistes"',
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0,1]',
      null,
      0
    ],
    [
      2,
      '"Signature"',
      -1,
      1,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["signature"]',
      '[0,1]',
      null,
      0
    ],
    [
      3,
      '"Assistant Anesthésiste : Scan"',
      -1,
      2,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0,1]',
      null,
      0
    ],
    [
      4,
      '"Signature"',
      -1,
      3,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["signature"]',
      '[0,1]',
      null,
      0
    ],
    [
      5,
      '"Chirurgien : Scan"',
      -1,
      4,
      null,
      '"Chirurgiens"',
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0,1]',
      null,
      0
    ],
    [
      6,
      '"Signature"',
      -1,
      5,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["signature"]',
      '[0,1]',
      null,
      0
    ],
    [
      7,
      '"Assistant Chirurgien : Scan"',
      -1,
      6,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0,1]',
      null,
      0
    ],
    [
      8,
      '"Signature"',
      -1,
      7,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["signature"]',
      '[1,0,2]',
      null,
      0
    ],
    [
      9,
      '"Infirmière Circulante: Scan"',
      -1,
      8,
      null,
      '"Infirmières"',
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0]',
      null,
      0
    ],
    [
      10,
      '"Signature"',
      -1,
      9,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["signature"]',
      '[0]',
      null,
      0
    ],
    [
      11,
      '"Infirmière Instrumentiste : Scan"',
      -1,
      10,
      null,
      null,
      '{"yes":[],"no":[],"num":[]}',
      '["scan"]',
      '[0]',
      null,
      0
    ],
    [
      12,
      '"Signature"',
      -1,
      11,
      null,
      null,
      '{"yes":[0],"no":[],"num":[]}',
      '["signature"]',
      '[0]',
      null,
      0
    ],
  ]
]

const path_list = [
  {
    path_id: 1,
    patient_id: 1,
    date : "01/01/2022",
  },
  {
    path_id: 2,
    patient_id:2,
    date : "01/01/2022",
  },
  {
    path_id: 3,
    patient_id:3,
    date : "01/01/2022",
  },
  {
    path_id: 4,
    patient_id:4,
    date : "01/01/2022",
  },
  {
    path_id: 5,
    patient_id:5,
    date : "01/01/2022",
  },
  {
    path_id: 6,
    patient_id:6,
    date : "01/01/2022",
  }
]

const paths = {
  1: {
    path_id: 1,
    patient_id: 1,
    date : "01/01/2022",
    checklists: [
      {id: 1, title: "Unité de soins", person: "Inf. de l'US qui prépare le patient ", fill: false,  type:2},
      {id: 2, title: "Accueil Patient BO", person: "Inf. BO qui accueille le patient", fill: false,  type:2 },
      {id: 3, title: "Avant Induction Anésthésique", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anést.", fill: false ,  type:1},
      {id: 4, title: "Avant Incision chirurgicale", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 5, title: "Compte des compresses", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false, type:0 },
      {id: 6, title: "Avant la sortie de la salle d'opération", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 7, title: "Signatures", person: "Chirurgiens/Anésthésistes/Infirmières", fill: false,  type:1 },
    ]},
  2: {
    path_id: 2,
    patient_id:2,
    date : "01/01/2022",
    checklists: [
      {id: 1, title: "Unité de soins", person: "Inf. de l'US qui prépare le patient ", fill: true,  type:2 },
      {id: 2, title: "Accueil Patient BO", person: "Inf. BO qui accueille le patient", fill: true,  type:2 },
      {id: 3, title: "Avant Induction Anésthésique", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anést.", fill: false,  type:1 },
      {id: 4, title: "Avant Incision chirurgicale", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 5, title: "Compte des compresses", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false, type:0 },
      {id: 6, title: "Avant la sortie de la salle d'opération", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 7, title: "Signatures", person: "Chirurgiens/Anésthésistes/Infirmières", fill: false,  type:1 },

    ]},
  3: {
    path_id: 3,
    patient_id:3,
    date : "01/01/2022",
    checklists: [
      {id: 1, title: "Unité de soins", person: "Inf. de l'US qui prépare le patient ", fill: true,  type:2 },
      {id: 2, title: "Accueil Patient BO", person: "Inf. BO qui accueille le patient", fill: false,  type:2 },
      {id: 3, title: "Avant Induction Anésthésique", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anést.", fill: false,  type:1 },
      {id: 4, title: "Avant Incision chirurgicale", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 5, title: "Compte des compresses", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false, type:0 },
      {id: 6, title: "Avant la sortie de la salle d'opération", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 7, title: "Signatures", person: "Chirurgiens/Anésthésistes/Infirmières", fill: false,  type:1 },
    ]},
  4: {
    path_id: 4,
    patient_id:4,
    date : "01/01/2022",
    checklists: [
      {id: 1, title: "Unité de soins", person: "Inf. de l'US qui prépare le patient ", fill: true,  type:2 },
      {id: 2, title: "Accueil Patient BO", person: "Inf. BO qui accueille le patient", fill: false,  type:2 },
      {id: 3, title: "Avant Induction Anésthésique", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anést.", fill: false,  type:1 },
      {id: 4, title: "Avant Incision chirurgicale", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 5, title: "Compte des compresses", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false, type:0 },
      {id: 6, title: "Avant la sortie de la salle d'opération", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 7, title: "Signatures", person: "Chirurgiens/Anésthésistes/Infirmières", fill: false,  type:1 },
    ]},
  5: {
    path_id: 5,
    patient_id:5,
    date : "01/01/2022",
    checklists: [
      {id: 1, title: "Unité de soins", person: "Inf. de l'US qui prépare le patient ", fill: true,  type:2 },
      {id: 2, title: "Accueil Patient BO", person: "Inf. BO qui accueille le patient", fill: false,  type:2 },
      {id: 3, title: "Avant Induction Anésthésique", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anést.", fill: false,  type:1 },
      {id: 4, title: "Avant Incision chirurgicale", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 5, title: "Compte des compresses", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false, type:0 },
      {id: 6, title: "Avant la sortie de la salle d'opération", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 7, title: "Signatures", person: "Chirurgiens/Anésthésistes/Infirmières", fill: false,  type:1 },
    ]},
  6: {
    path_id: 6,
    patient_id:6,
    date : "01/01/2022",
    checklists: [
      {id: 1, title: "Unité de soins", person: "Inf. de l'US qui prépare le patient ", fill: true,  type:2 },
      {id: 2, title: "Accueil Patient BO", person: "Inf. BO qui accueille le patient", fill: false,  type:2 },
      {id: 3, title: "Avant Induction Anésthésique", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anést.", fill: false,  type:1 },
      {id: 4, title: "Avant Incision chirurgicale", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 5, title: "Compte des compresses", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false, type:0 },
      {id: 6, title: "Avant la sortie de la salle d'opération", person: "Chirurgien/Inf. \n Circul./Instrumentiste/Anest.", fill: false,  type:1 },
      {id: 7, title: "Signatures", person: "Chirurgiens/Anésthésistes/Infirmières", fill: false,  type:1 },
    ]}
}

const user_list = [
  {"id":1, user_code:"1234567", lastname: "Jonas", firstname: "Michel"},
  {"id":2, user_code:"7654321", lastname: "Van Acker", firstname: "Denise"}
]

const users = {
  1: {"id":1, user_code:"1234567", lastname: "Jonas", firstname: "Michel", role_name: "Assistant Anést.", role_id: 1},
  2: {"id":2, user_code:"7654321", lastname: "Van Acker", firstname: "Denise", role_name: "Infirmière US", role_id: 2}
}

// Roles (de users) : 1 (rôle étendu), 2 (rôle plus limité)
// Types (de checklist) : 0 (Comptage), 1 (Classique), 2 (étendue)
const role_cross_type = {
  1 : [0,1,2],
  2 : [0,1]
}

const patient_list = [
  {"id": 1, "patient_code":"12345678", "lastname": "Dubois", "firstname": "Germaine", },
  {"id": 2, "patient_code":"65464684", "lastname": "Vandamme", "firstname": "Gilbert", },
  {"id": 3, "patient_code":"54875149", "lastname": "Franklin", "firstname": "Rachel", },
  {"id": 4, "patient_code":"56892134", "lastname": "Leroy", "firstname": "Michel", },
  {"id": 5, "patient_code":"96587412", "lastname": "Girard", "firstname": "Alexandra", },
  {"id": 6, "patient_code":"78451236", "lastname": "Van den Broucke", "firstname": "Kevin", },
]

/*List of all patients*/
const patients = {
  1: {"id": 1, "patient_code":"12345678", "lastname": "Dubois", "firstname": "Germaine", "gender": "F", "dateofbirth": "01/01/1940",
    optional_data: {"diabetic":"faux", "difficult_intubation":"vrai", "insulin":"faux", "xarelto":"faux"}, photo: "photos/photo1.jpg", "consent_pdf":"photos/pdf1.jpg",
    "type":"major", "intervention_name":"PTG"},
  2: {"id": 2, "patient_code":"65464684", "lastname": "Vandamme", "firstname": "Gilbert", "gender": "M", "dateofbirth": "01/01/2017",
    optional_data: {"diabetic":"vrai", "difficult_intubation":"vrai", "insulin":"faux", "xarelto":"faux"}, photo: "photos/photo2.jpg", "consent_pdf":"photos/pdf1.jpg",
    "type":"minor", "intervention_name":"Ablation Végétations"},
  3: {"id": 3, "patient_code":"54875149", "lastname": "Franklin", "firstname": "Rachel", "gender": "F", "dateofbirth": "01/01/1986",
    optional_data: {"diabetic":"faux", "difficult_intubation":"faux", "insulin":"faux", "xarelto":"faux"}, photo: "photos/photo3.jpg", "consent_pdf":"photos/pdf1.jpg",
    "type":"minor", "intervention_name":"Varice bilatérale"},
  4: {"id": 4, "patient_code":"56892134", "lastname": "Leroy", "firstname": "Michel", "gender": "M", "dateofbirth": "01/01/1958",
    optional_data: {"diabetic":"vrai", "difficult_intubation":"vrai", "insulin":"vrai", "xarelto":"vrai", "bloodbags":3}, photo: "photos/photo4.jpg", "consent_pdf":"photos/pdf1.jpg",
    "type":"major", "intervention_name":"Anévrisme Aorte Abdominale"},
  5: {"id": 5, "patient_code":"96587412", "lastname": "Girard", "firstname": "Alexandra", "gender": "F", "dateofbirth": "01/01/1986",
    optional_data: {"diabetic":"faux", "difficult_intubation":"faux", "insulin":"faux", "xarelto":"faux"}, photo: "photos/photo5.jpg", "consent_pdf":"photos/pdf1.jpg",
    "type":"minor", "intervention_name":"Varices"},
  6: {"id": 6, "patient_code":"78451236", "lastname": "Van den Broucke", "firstname": "Kevin", "gender": "M", "dateofbirth": "01/01/1970",
    optional_data: {"diabetic":"faux", "difficult_intubation":"faux", "insulin":"faux", "xarelto":"faux"}, photo: "photos/photo6.jpg", "consent_pdf":"photos/pdf1.jpg",
    "type":"minor", "intervention_name":"Varices"},
}

const lists = {
  "meds": ["Mc","Lis","Kl","Eft", "Aucun"],
  "diseases": ["Hépatite A", "Hépatite B", "Hépatite C","Covid", "HIV", "Tuberculose", "MRSA", ],
  "patient-states" : ["Conscient","Inconscient","Dément", "Dyscommunicant"],
  "alergy-meds" : ["Latex","Peniciline","Insuline", "Ibuprofène", ],
  "alergy-aliments" : ["Gluten","Lactose","Arachides", "Banane", "Kiwi", "Noix", "Fruits secs" ],
  "nurses" : ["Avril Charpentier"," Emilie Lachance","Camille Dupond", "Alix Lefurgey", "Bastien Bellerose", "Jérémy Duchamps",],
  "anesthesists": ["Jean Dupond", "Carole Marchal", "Louis Libier", "Charles Van Acker"],
  "room" : ["N1","N2","N3","N4","N5","N6","U1","U2","U3","U4","U5","U6","U7","U8","U9","U10","I1","I2","I3","I4"],
  "antibiotics" : ["Cefazoline","Zinacef","Flagyl"],
  "anesthesia" : ["AG", "AG + épidurale", "épidurale" , "rachi", "bloc" ],
  "problems" : ["Complications médicales", "Dysfonct. matér. : Préparation incorrecte", "Dysfonct. matér. : Problème de prothèse", "Dysfonct. matér. : Problème de stérilisation",]
}

const lists_trad = {"meds":"Médicaments", "diseases":"Maladies", "patient-states":"Etats Patients",
  "alergy-meds":"Allergies Médicamenteuses", "alergy-aliments":"Allergies Alimentaires", "nurses": "Infirmières",
  "anesthesists": "Anesthésistes", "room": "Salle", "antibiotics": "Antibiotiques"}

const alerts = [
]

export {checklist_arrays, path_list, paths, patient_list, patients, user_list, users, role_cross_type, lists, lists_trad, alerts}