import React, {useEffect, useReducer, useState} from "react";
import axios from "./axios";
import "./styles/App.css";
import * as temp_data from "./utils/temporary_data.js";
import * as utils from "./utils/utils.js";
import {CreationAppNavbar} from "./components_creation_app/navbar.js";
import {CreateBox} from "./components_creation_app/creation_box.js"
import {Credits} from "./components_common/credits.js"
import {PatientBox} from "./components_main_app/patient_box.js"
import {ChecklistItem} from "./components_common/item.js"
import {SectionTitle} from "./components_main_app/section_title.js";
import {ValidationButton} from "./components_main_app/validation_button";
import {AlertsBox} from "./components_main_app/alerts_box";
import {Title} from "./components_main_app/title";
import {Home} from "./components_main_app/home";
import * as calls from "./calls";
import {CountingTable} from "./components_main_app/couting_table";
import QrcodeScanner from "./components_main_app/qrcodescanner";
import {UserBox} from "./components_main_app/user_box";
import {Review} from "./components_main_app/review";
import {Login} from "./components_main_app/login";
import {Link} from "react-router-dom";

/*App Function
* -Declare all the variables needed in different component
* -Return the skeleton of the application
* (Navbar, UserBox, PatientBox, Home, Title, ChecklistItem(s), CreateBox, Credits, Scanners)
* */

export default function CreationApp({props}) {


  /******* Variables declaration and initialization ********/

  const is_local = false

  /*Function needed (at the moment), to force the components_common to update when needed*/
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  let [checklistList, setChecklistList] = useState(null)
  let [checklist, setChecklist] = useState(null)
  let [checklistId, setChecklistId] = useState(-1)
  let [currentQuestion, setCurrentQuestion] = useState(null)
  let [result, setResult] = useState({})
  let [pbresult, setPbResult] = useState({})
  let [visibleList, setVisibleList] = useState([])
  let [isPreCheckDone, setIsPreCheckDone] = useState([])

  let [commentMode, setCommentMode] = useState(true)
  let [debugMode, setDebugMode] = useState(false)
  let [precheckMode, setPrecheckMode] = useState(true)
  let [warningId, setWarningId] = useState(0)

  let [isWaitingList, setIsWaitingList] = useState(true)



  /* Initial set of isDict state variable
  * -init_dict : dict containing {0:true} for each possible answer (yes, no, etc), defined in utils.js
  * -isDict : dict containing, for each possible response defined in utils.js,
  * a dict containing the questions id's that have this response checked at this current time, for the current checklist
  * */
  let init_dict = {}
  utils.list_possible_answer.forEach(function (answer){init_dict[answer]={0:true}})
  const [isDict, setIsDict] = useState(init_dict)


  /******* App functions declaration ********/

  /* Reset the state variables who are dependent of the current checklist
  * */
  function reset (){
    setWarningId(0)
    setResult({})
    setPbResult({})
    let init_dict_ = {}
    utils.list_possible_answer.forEach(function (answer){init_dict_[answer]={0:true}})
    setIsDict(init_dict_)
    setIsPreCheckDone([])
  }


  /******* Variables preparation for the question item recursive first call ********/


  /* Filter (check of the cond's) of the checklist  initial values (i.e. the questions at the first level of the tree)*/
  let values = null
  visibleList = []
  if (checklist && checklist.values) {
    values = values_filter_cond(checklist.values, isDict, {}, true)
    values.forEach(value => value.check.length ? visibleList.push(value.id): null)
  }

  let dicts = [isDict, setIsDict, null, result, pbresult, setResult, setPbResult, isPreCheckDone, setIsPreCheckDone, visibleList, setVisibleList, false, false ]

  let next_scan_item = values ? values.filter(item => !result[item.id] && item.check.includes("scan")) : null
  let next_scan_item_id =  next_scan_item && next_scan_item.length ? next_scan_item[0].id : null


  /******* App return call ********/

  /* Return the different components_common.
  * We define also the background and a hidden bottom navbar to avoid problems with the background vertical limits
  * Content (in term of components_common) :
  * - Navbar
  * ~ Credits (if credit mode)
  * ~ App page :
  *   ~ Creation box (if create mode)
  *   ~ Menu sections (if home mode) :
  *     ~ User / Patient selection (if no checklist list already set) :
  *       ~ User selection and scanner (if no user already set)
  *       ~ Patient selection (if user already set) :
  *         ~ Patient scanner (if patient selected)
  *     ~ Home section, showing the list of checklists (if checklist list already set)
  *   ~ App Checklist section (if not home mode) :
  *     ~ Title section, presenting checklist (if not create mode)
  *     ~ Alert section (if not create mode)
  *     ~ Checklist content section (if checklist variable set) :
  *       ~ Normal Checklist (if checklist.counter false)
  *         - Checklist Items
  *       ~ Counter Checklist (if checklist.counter true)
  *     ~ Validation section (if not create mode)
  */
  return (
    <div>
      <div className="min-vh-100 content-page bg-color-custom">
        <div>
          {<CreationAppNavbar props = {{
            checklistList,
            reset,
            forceUpdate,
            result,
            setCurrentQuestion,
            checklist,
            setChecklistList,
            is_local,
            debugMode,
            setDebugMode,
            commentMode,
            setCommentMode
          }}/>}
          <div>
            <CreateBox props={{
              checklist,
              setChecklist,
              checklistList,
              setChecklistList,
              checklistId,
              setChecklistId,
              forceUpdate,
              currentQuestion,
              setCurrentQuestion,
              reset,
              is_local,
              setIsWaitingList
            }} />
            <div id={"title"}>
                {checklist ? <div>
                    {checklist.type !== 0 ? // checklist.type !== 0
                      <div className={"container p-0 border-bottom border shadow-sm rounded "}>
                        {values ? values.map((i, index) => (
                            <div>
                              {i.section_title ? <SectionTitle section_title={i.section_title} index={index}/> :
                                <div className={"bg-primary " + (index ? " border-top" : "")}/>}
                              <div className=
                                     {"pb-3 px-3 pt-3 " +
                                       (index === values.length - 1 ? "rounded rounded-0-top " : null) +
                                       (index || i.section_title ? "" : " rounded rounded-0-bottom ") +
                                       (i.importance ? " iq-bg-danger" : " bg-color-custom")}>
                                <ChecklistItem key={JSON.stringify(checklistId) + i.id} init_items={checklist} item={i}
                                               dicts={dicts}
                                               forceUpdate={forceUpdate} values_filter_cond={values_filter_cond}
                                               creationMode={true}
                                               currentId={currentQuestion ? currentQuestion.id : null}
                                               warningId={warningId} precheckMode={precheckMode}
                                               is_root={true} alertList={[]}
                                               scan_bookmark={next_scan_item_id === i.id}
                                               checklist_name = {checklist.name}
                                               is_local = {is_local}
                                />
                              </div>
                            </div>))
                          :
                          null}
                      </div>
                      :
                      <CountingTable result={result} setResult={setResult}/>
                    }</div>
                  :
                  <div>{isWaitingList ?
                    <div className="d-flex justify-content-center m-2">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                    :
                    <div
                      className={"container iq-card bg-white mx-auto mb-0 mt-2 p-2 text-center shadow-sm border justify-content-center "}>
                      <div
                        className={"card-body iq-card mx-auto my-3 text-center p-2 shadow-sm border justify-content-center"}>
                        <h6 className="card-text text-danger m-0 p-0">
                          <div data-icon="&#xe063;" className="icon text-danger"> La checklist est introuvable, problème de connexion ()
                          </div>
                        </h6>
                      </div>
                    </div>
                  }</div>
                }

              </div>
          </div>

        </div>

        <div>
          <nav className="navbar">
            <label className="navbar-brand">{null}</label>
          </nav>
        </div>
      </div>
    </div>
  );
}

/* Filter the values array (containing all the roots question of the checklist)
* by keeping only the values that validates all conditions
* We check all the response condition of the item
* (for ex, if item.cond contains {"yes": [1,4]}, we check if isDict["yes"] contains 1 and 4 )
* + all num conditions check
*/
function values_filter_cond(values, isDict, numDict, creationMode, debugMode) {

  return debugMode ? values : values.filter( item=>
      Object.keys(item.cond).every(
        function(answer){
          return (answer === "num" || !item.cond[answer] || item.cond[answer].every(
            function(elm) {return isDict[answer][elm];}
          ))
        }
      )
      && (creationMode || item.cond.num.every(
        function(elm) {
          return !numDict[elm.var] || utils.simple_operation(numDict[elm.var],elm.op, elm.val) }
      ))
  )
}



