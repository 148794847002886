import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./MainApp";
import CreationApp from "./CreationApp"
import {Credits} from "./components_common/credits";
import {useState} from "react";

function App() {

  let [test, setTest] = useState("bonjour")
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp props={{test}}/>}/>
        <Route path="/creation" element={<CreationApp/>}/>
      </Routes>
    </BrowserRouter>
  )
}
export {App}